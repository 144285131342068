<template>
  <div>
    <div class="q-pa-sm block text-center q-item-no-border full-width">
      <q-form @submit.prevent="handleSubmitDimmensions">
        <q-card class="col no-border" flat borderless>

          <!-- All inputs and buttons in a single row -->
          <q-card-section class="row no-wrap items-center">
            <!-- First column for weight -->
            <div class="col-2">
              <q-btn-group class="q-px-xs">
                <editable-input-field
                    :label="$t('Weight')"
                    :value="userWeight || weight"
                    :input-label="`${this.$t('Weight')} (${$t(appOptions.defaultDimensions.weightUnit)})`"
                    :update-function="newValue => handleNumberUpdate('weight', newValue)"
                    @change-catch-all="enableCatchAll"
                />
              </q-btn-group>
            </div>

            <!-- Second column for width, height, length -->
            <div class="col-6">
              <q-btn-group class="q-px-xs">
                <editable-input-field
                    :label="$t('W')"
                    :value="userWidth || width"
                    :input-label="`${$t('W')} (${$t(appOptions.defaultDimensions.dimensionUnit)})`"
                    :update-function="newValue => handleNumberUpdate('width', newValue)"
                    @change-catch-all="enableCatchAll"
                />

                <editable-input-field
                    :label="$t('H')"
                    :value="userHeight || height"
                    :input-label="`${$t('H')} (${$t(appOptions.defaultDimensions.dimensionUnit)})`"
                    :update-function="newValue => handleNumberUpdate('height', newValue)"
                    @change-catch-all="enableCatchAll"
                />

                <editable-input-field
                    :label="$t('L')"
                    :value="userLength || length"
                    :input-label="`${$t('L')} (${$t(appOptions.defaultDimensions.dimensionUnit)})`"
                    :update-function="newValue => handleNumberUpdate('length', newValue)"
                    @change-catch-all="enableCatchAll"
                />
              </q-btn-group>
            </div>

            <!-- Third column for ship date -->
            <div class="col-2">
              <q-btn-group class="q-px-xs">
                <editable-input-field
                    :label="$t('Ship date')"
                    input-type="date"
                    :value="userSendDate || sendDate"
                    :input-label="`${this.$t('Ship date')}`"
                    :update-function="newValue => handleNumberUpdate('sendDate', newValue)"
                    @change-catch-all="enableCatchAll"
                />
              </q-btn-group>
            </div>

            <!-- Fourth column for buttons (Calculate and Save) -->
            <div class="col-1 text-left">
              <q-btn
                  color="primary"
                  icon="mdi-calculator"
                  text-color="white"
                  class="q-mb-sm q-mr-xs"
                  :label="$t('Calculate')"
                  no-caps
                  size="sm"
                  :disable="isWaitOn"
                  type="submit"
              />
            </div>
            <div class="col-1 q-pl-none text-left">
              <q-btn
                  color="positive"
                  icon="mdi-content-save"
                  text-color="white"
                  class="q-mb-sm"
                  size="sm"
                  :label="$t('Save')"
                  :disable="!isSaveActive"
                  no-caps
                  @click="handleSave"
              />
            </div>
          </q-card-section>

        </q-card>
      </q-form>
    </div>

    <q-card-section v-if="isWaitOn">
      <div class="q-py-sm text-subtitle1 row items-center justify-center">
        <q-spinner
            color="primary"
            size="24px"
            class="q-mr-sm"
        />
        <strong>{{ $t('Please wait. We are loading the rates.') }}</strong>
      </div>
    </q-card-section>

    <div v-else>
      <!-- Tabs for the delivery types (simple, service_point, self_service_point) -->
      <q-tabs v-model="selectedTab" align="left" dense>
        <q-tab
            v-for="(group, index) in results"
            :key="index"
            :name="group.type"
        >
          <strong>
            <q-icon :name="group.name.icon" size="sm" class="q-mr-sm"/>
            {{ group.name.title }}</strong>
          <div v-html="getTabTitle(group)"/>
        </q-tab>
      </q-tabs>

      <!-- Panels corresponding to each delivery type -->
      <q-tab-panels v-model="selectedTab">
        <q-tab-panel
            v-for="(group, index) in results"
            :key="index"
            :name="group.type"
        >
          <q-table
              row-key="id"
              class="full-width table--only-header"
              :rows-per-page-label="$t('Rows per page')"
              :rows="group.items"
              :columns="columns"
              :pagination="{ page: 1, rowsPerPage: 250, totalRowsNumber: group.items.length }"
              virtual-scroll
              flat
              :sort-method="customSortMethod"
          >
            <template v-slot:body="props">
              <q-tr :props="props">
                <q-td
                    key="before"
                    :props="props"
                >
                  <input
                      :id="'rate_id_' + props.row.type + '_' + props.row.id + '_' + props.row.deliveryService.id"
                      type="radio"
                      name="rate"
                      :disable="disabled"
                      :checked="props.row.id === selectedRateId
                         && props.row.type === this.deliveryRequest._embedded.rate.type
                         && props.row.deliveryService.id === this.deliveryRequest._embedded?.deliveryService?.id"
                      :model-value="props.row.deliveryService.id + '_' + props.row.id"
                      @click="handleRateClick(props.row)"
                  >

                  <label :for="'rate_id_' + props.row.id"/>
                </q-td>

                <q-td
                    key="deliveryServiceLogo"
                    :props="props"
                >
                  <img
                      v-if="deliveryServices[props.row.deliveryService.id].logo"
                      :src="checkLogo(props.row)"
                      :title="props.row.deliveryService.name"
                      style="width: 50px; height: 50px; object-fit: contain;"
                  >

                  <h5
                      v-if="!deliveryServices[props.row.deliveryService.id].logo"
                      class="q-my-none"
                  >
                    {{ props.row.deliveryService.name }}
                  </h5>
                </q-td>

                <q-td
                    key="shipping"
                    :props="props"
                    class="text-wrap"
                    style="white-space: pre-wrap; "
                >
                  <h5 class="q-my-none text-caption">
                    {{ `${props.row.name} (${props.row.id})` }} - {{ props.row.type }}
                  </h5>

                  <div v-if="props.row.errors">
                    <q-badge
                        v-if="props.row.errors"
                        color="red"
                        text-color="white"
                        icon="mdi-alert-circle"
                        :label="getErrorMessage(props.row.errors)"
                        style="white-space: normal; word-wrap: break-word;"
                    >
                      <q-tooltip>
                        <div class="tooltip-content">
                          <div v-if="typeof props.row.errors === 'object'">
                            <div v-for="(value, key) in props.row.errors" :key="key">
                              <strong>{{ key }}:</strong> {{ value }}
                            </div>
                          </div>
                          <div v-else>
                            {{ props.row.errors }}
                          </div>
                        </div>
                      </q-tooltip>
                    </q-badge>
                  </div>
                </q-td>

                <q-td
                    key="deliveryTime"
                    :props="props"
                >
                  <div v-if="props.row.deliveryTime">
                        <span v-if="props.row.deliveryTime.min">
                          {{ formatDate(props.row.deliveryTime.min) }}
                        </span>
                    <span v-else-if="!props.row.deliveryTime.min && props.row.deliveryTime.max">
                          {{ formatDate(props.row.deliveryTime.max) }}
                        </span>
                    <span v-else-if="isDifferentDates(props.row.deliveryTime.min, props.row.deliveryTime.max)">
                          &mdash; {{ formatDate(props.row.deliveryTime.max) }}
                        </span>
                    <span v-else>
                          <q-icon name="mdi-clock-alert" size="sm"/>
                        </span>
                  </div>
                  <div v-else>
                    <q-icon name="mdi-clock-alert" size="sm"/>
                  </div>
                </q-td>

                <q-td
                    key="price"
                    :props="props"
                >
                      <span v-if="props.row.price">
                        <strong>
                          {{ Number(props.row.price).toFixed(2) }}

                          <span v-if="! getCurrencyIcon(props.row.currency) && props.row.currency">
                            {{ props.row.currency.code }}
                          </span>
                        </strong>

                        <q-icon
                            v-if="getCurrencyIcon(props.row.currency)"
                            :name="getCurrencyIcon(props.row.currency)"
                        />
                      </span>
                  <span v-else>
                        <q-icon name="mdi-alert-circle" size="sm" color="orange"/>
                      </span>
                </q-td>
              </q-tr>

              <!-- Additional row for service point locator -->
              <q-tr
                  v-if="props.row.id === deliveryRequest._embedded?.rate?.id && deliveryRequest._embedded?.rate?.type === 'service_point' && props.row.type === 'service_point'">
                <q-td :colspan="columns.length" class="q-pa-md">
                  <!-- Service Points Locator Component -->
                  <service-points-map
                      :is-calculator="true"
                      :sender="deliveryRequest._embedded.sender"
                      :locality="deliveryRequest._embedded.recipientLocality"
                      :rate="deliveryRequest._embedded.rate"
                      :service-point="deliveryRequest._embedded.servicePoint"
                      @submit="handleMarkerSelect"
                  />
                </q-td>
              </q-tr>
            </template>
          </q-table>
        </q-tab-panel>
      </q-tab-panels>
    </div>
  </div>
</template>

<script>
// Vuex
import { mapActions, mapGetters, mapMutations } from 'vuex'

// Services
import { HALApiService } from '../../services'

// Configs
import PinsConfig from '../../config/PinsConfig'

import { LMap, LTileLayer, LMarker, LPopup, LIcon, LLayerGroup } from '@vue-leaflet/vue-leaflet/src/lib'
import EditableInputField from '@/apps/app/components/delivery-services/EditableInputField.vue'
import ServicePointsMap from '@/apps/app/components/service-points-map/ServicePointsMap.vue'

export default {
  emits: ['sender-change', 'locality-change', 'calculation', 'on-service-point-change', 'rate', 'update-dimensions'],
  components: {
    ServicePointsMap,
    EditableInputField,
    'l-map': LMap,
    'l-tile-layer': LTileLayer,
    'l-marker': LMarker,
    'l-popup': LPopup,
    'l-icon': LIcon,
    'l-layer-group': LLayerGroup
  },
  props: {
    disabled: {
      type: Boolean,
      default: false
    },
    debug: {
      type: Boolean,
      default: false
    },
    clearCache: {
      type: Boolean,
      default: false
    },
    deliveryRequest: Object,
    weight: Number,
    width: Number,
    height: Number,
    length: Number,
    divisor: {
      type: Number,
      default: 5000
    },
    sendDate: {
      type: String,
      default () {
        // Get today's date
        const today = new Date()
        // Format the date as a string in YYYY-MM-DD format
        const yyyy = today.getFullYear()
        const mm = String(today.getMonth() + 1).padStart(2, '0') // Months are zero-based
        const dd = String(today.getDate()).padStart(2, '0')
        return `${yyyy}-${mm}-${dd}`
      }
    }
  },
  data () {
    return {
      addressModel: {
        sender: this.sender,
        locality: this.locality
      },
      isSaveActive: false,
      pinTypes: PinsConfig,
      calculation: {},
      columnsStatus: [
        {
          name: 'deliveryServiceLogo',
          align: 'left'
        },
        {
          name: 'status',
          align: 'left'
        }
      ],
      columns: [
        {
          name: 'before',
          align: 'left'
        },
        {
          name: 'deliveryServiceLogo',
          align: 'left'
        },
        {
          label: this.$t('Shipping'),
          name: 'shipping',
          sortable: true,
          align: 'left'
        },
        {
          label: this.$t('Time'),
          name: 'deliveryTime',
          sortable: true,
          align: 'left'
        },
        {
          label: this.$t('Price'),
          name: 'price',
          sortable: true,
          align: 'left'
        }
      ],
      rows: [],
      totalRecords: 0,
      deliveryServices: {},
      deliveryServicesValues: [],
      servicePoints: [],
      center: [43.204666, 27.910543],
      zoom: 10,
      url: 'http://{s}.tile.osm.org/{z}/{x}/{y}.png',
      attribution: '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors',
      markers: [],
      hotline: null,
      latLngArray: [],
      minValue: 0,
      maxValue: 1,
      senderGeo: null,
      isShowDebug: false,
      marker: null,
      currentPoint: {},
      tab: 'prices',
      userSendDate: null,
      userWidth: null,
      userHeight: null,
      userLength: null,
      userWeight: null,
      result: {},
      lastLocalitySearch: '',
      search: '',
      isExpandedStatus: false,
      isWaitOn: false,
      selectedTab: 'simple', // Default tab
    }
  },
  computed: {
    ...mapGetters([
      'appOptions'
    ]),
    filteredServicePoints () {
      const text = this.search.toLowerCase()
      return this.servicePoints.filter(x => JSON.stringify(x).toLowerCase().includes(text))
    },
    results () {
      return Object.values(this.result)
    },
    servicePointsWithGeo () {
      return this.servicePoints.filter(point => {
        return typeof point.geo === 'string' && point.geo.length > 0
      })
    },
    schema () {
      return {
        groups: [
          {
            styleClasses: 'row',
            fields: [
              {
                type: 'multiselect',
                label: this.$t('Sender'),
                wrapperStyleClasses: 'col-12 col-md-4 q-pa-xs',
                field: 'sender',
                value: this.addressModel.sender,
                required: true,
                disabled: this.disabled,
                customLabel (row) {
                  if (row && typeof row === 'object') {
                    return `${row.name} (${row.id})`
                  }

                  return row
                },
                onScroll: (search, page) => {
                  const query = {
                    per_page: 25,
                    page,
                    search,
                    filter: [
                      { type: 'eq', field: 'state', value: 'active' }
                    ]
                  }

                  return this.$service.sender.getAll(query)
                },
                onChange: (sender) => {
                  this.$emit('sender-change', sender)
                  this.addressModel.sender = sender

                  if (this.addressModel.sender && this.addressModel.locality) {
                    this.loadItems()
                  }
                }
              },
              {
                type: 'multiselect',
                label: this.$t('Location'),
                wrapperStyleClasses: 'col-12 col-md-8 q-pa-xs',
                field: 'locality',
                value: this.addressModel.locality,
                disabled: this.disabled || this.isFocusedPostcode || this.postcodesLoading,
                required: true,
                minimumLength: 3,
                customLabel (row) {
                  if (!row || typeof row !== 'object') {
                    return ''
                  }

                  let base = row.type + ' ' + row.name + (row.postcode ? ', ' + row.postcode : '')

                  if (row.embedded && row._embedded.area) {
                    base += ' (' + row._embedded.area.name + ')'
                  }

                  return base
                },
                onScroll: (search, page) => {
                  const query = {
                    per_page: 25,
                    page,
                    search,
                    filter: [
                      { type: 'eq', field: 'state', value: 'active' }
                    ]
                  }

                  return this.$service.locality.getAll(query)
                },
                onChange: (locality) => {
                  this.$emit('locality-change', locality)
                  this.addressModel.locality = locality

                  if (this.addressModel.sender && this.addressModel.locality) {
                    this.loadItems()
                  }
                }
              }
            ]
          }
        ]
      }
    }
  },
  watch: {
    locality: {
      handler () {
        this.loadItems()

        if (this.locality) {
          this.buildMap()
        }
      },
      deep: true
    },
    postcode () {
      this.loadItems()
    },
    estimatedCost () {
      this.loadItems()
    },
    payment () {
      this.loadItems()
    },
    calculation () {
      this.$emit('calculation', this.calculation)
    },
    servicePoint: {
      handler (newValue) {
        const { latitude, longitude } = newValue._embedded.locality.geo
        this.center = [latitude, longitude]
      },
      deep: true
    }
  },
  mounted () {
    this.buildMap()

    this.loadItems()

    this.initializeRateType()
  },
  unmounted () {
    this.isSaveActive = false
  },
  updated () {
    if (this.locality) {
      this.updateMapSize()
    }
  },
  methods: {
    ...mapActions([
      'loadLocalities',
      'loadServicePoint',
      'loadServicePoints',
      'loadSenders'
    ]),
    ...mapMutations([
      'addErrorNotification'
    ]),
    initializeRateType () {
      if (this.deliveryRequest && this.deliveryRequest._embedded.rate) {
        // Check if the rate type is 'simple' but a servicePoint is set, switch it to 'service_point'
        if (
            this.deliveryRequest._embedded.rate.type === 'simple' &&
            this.deliveryRequest._embedded.servicePoint
        ) {
          this.deliveryRequest._embedded.rate.type = 'service_point' // Change the rate type for calculator purposes
        }

        // Set the initial tab based on the rate type
        this.selectedTab = this.deliveryRequest._embedded.rate.type
        this.selectedRateId = this.deliveryRequest._embedded.rate.id
      }
    },
    customSortMethod (items, sortBy, descending) {
      // Clone the items array to avoid modifying the original data
      const clonedItems = [...items]

      // Custom sorting logic
      clonedItems.sort((a, b) => {
        const aValue = a[sortBy]
        const bValue = b[sortBy]

        if (descending) {
          return aValue > bValue ? -1 : 1
        } else {
          return aValue > bValue ? 1 : -1
        }
      })

      return clonedItems
    },
    formatDate (date) {
      if (!date) return null
      return this.$moment(date.date).format('ddd, MMM D h:mm A')
    },
    isDifferentDates (minDate, maxDate) {
      return minDate && maxDate && minDate !== maxDate
    },
    handleSave () {
      const dimensions = {
        weight: this.userWeight || this.weight,
        dimensions: {
          x: `${this.userWidth || this.width}`,
          y: `${this.userHeight || this.height}`,
          z: `${this.userLength || this.length}`
        }
      }
      this.$emit('update-dimensions', dimensions)
    },
    handleSearch (e) {
      this.search = e
    },
    handleSubmitDimmensions (e) {
      e.preventDefault()
      this.isSaveActive = true
      this.loadItems()
    },
    // Get pin icon by delivery service
    createIcon (point) {
      const service = this.deliveryServices[point.deliveryService.id]
      const pin = this.pinTypes[service.name] || this.pinTypes.default

      return this.isChoosed(this.createGeo(point.geo))
          ? this.pinTypes.selectedPin
          : pin
    },
    handleTabChange (tabName) {
      this.tab = tabName
    },
    findLogo (id) {
      return (this.deliveryServices[id] || {}).logo
    },
    handleMarkerSelect (point) {
      this.deliveryRequest._embedded.servicePoint = point.servicePoint
    },
    openPopUp (latLng) {
      this.$refs.features.leafletObject.openPopup(latLng)
    },
    getMarkerSize (coords) {
      return this.isChoosed(coords)
          ? [34, 50]
          : [26, 40]
    },
    isChoosed (coords) {
      return !!this.marker && coords[0] === this.marker[0] && coords[1] === this.marker[1]
    },
    handleRateClick (row) {
      const types = ['service_point', 'self_service_point']

      if (types.includes(row.type) && this.servicePoints.length > 0) {
        this.center = this.createGeo(this.locality.geo)

        setTimeout(() => {
          this.zoom = 13
        }, 500)
      }

      this.isSaveActive = true

      this.$emit('rate', row, this.isExpanded)
    },
    handleServicePoint (point) {
      this.$emit('on-service-point-change', point)
    },
    createGeo (value = '') {
      const indexOfOpen = value.indexOf('(') + 1
      const indexOfClose = value.indexOf(')')

      return value.slice(indexOfOpen, indexOfClose).split(' ').map(Number).reverse()
    },
    // Get locality by sender address
    getSenderGeo () {
      if (this.senderGeo) {
        return Promise.resolve(this.senderGeo)
      }

      if (!this.senderAddress) {
        return Promise.reject(new Error(this.$t('No sender geo')))
      }

      let senderLocality = this.senderAddress.locality || (this.senderAddress._embedded || {}).locality

      if (!senderLocality) {
        return Promise.reject(new Error(this.$t('Sender address locality not set')))
      }

      return HALApiService.getLink(senderLocality._links.self.href)
          .then(data => {
            if (!data.geo) {
              return Promise.reject(new Error(this.$t('No sender geo')))
            }

            this.senderGeo = this.createGeo(data.geo)

            return this.senderGeo
          })
    },
    setRoute () {
      if (this.latLngArray.length === 2) {
        return Promise.resolve(this.latLngArray)
      }

      return this.getSenderGeo()
          .then(senderGeo => {
            this.latLngArray[0] = [...senderGeo, 0]

            if (this.locality.geo) {
              this.latLngArray[1] = [...this.createGeo(this.locality.geo), 1]
            }

            return this.latLngArray
          })
    },
    fitBounds () {
      return this.setRoute()
          .then(latLngArray => {
            if (latLngArray.length > 0 && this.$refs.calculatorMap.mapObject) {
              this.$refs.calculatorMap.mapObject.fitBounds(window.L.latLngBounds(latLngArray), {
                maxZoom: 13,
                minZoom: 3
              })
            }

            this.updateMapSize()

            return latLngArray
          })
    },
    buildMap () {
      if (!this.locality || !this.locality.geo) {
        this.geolocate()
        return
      }

      this.fitBounds()
          .then(() => {
            if (this.locality.geo) {
              this.center = this.createGeo(this.locality.geo)

              setTimeout(() => {
                this.zoom = 13
              }, 500)
            }
          })
          .catch(err => {
            console.debug(err.message)

            if (this.locality.geo) {
              this.center = this.createGeo(this.locality.geo)

              setTimeout(() => {
                this.zoom = 13
              }, 500)
            } else {
              this.geolocate()
            }
          })
    },
    geolocate () {
      navigator.geolocation.getCurrentPosition(position => {
        this.center = [position.coords.latitude, position.coords.longitude]
      })
    },
    updateMapSize () {
      if (this.$refs.calculatorMap.mapObject) {
        this.$refs.calculatorMap.mapObject.invalidateSize()
      }
    },
    getCurrencyIcon (currency) {
      const currencies = {
        USD: 'mdi-currency-usd',  // US Dollar
        EUR: 'mdi-currency-eur',  // Euro
        RUB: 'mdi-currency-rub',  // Russian Ruble
        KZT: 'mdi-currency-kzt',  // Kazakhstani Tenge
      }

      return currencies[currency] || null
    },

    getPostcode () {
      if (this.postcode) {
        return this.postcode
      }

      // if (this.addressModel.locality.postcode) {
      //   return this.addressModel.locality.postcode
      // }

      return null
    },
    checkLogoServices (service) {
      if (service.logo.includes('http')) {
        return service.logo
      } else {
        return this.appOptions.defaultServer + service.logo
      }
    },
    checkLogo (service) {
      if ((this.deliveryServices[service.deliveryService.id] || {}).logo.includes('http')) {
        return (this.deliveryServices[service.deliveryService.id] || {}).logo
      } else {
        return this.appOptions.defaultServer + this.deliveryServices[service.deliveryService.id].logo
      }
    },
    getErrorMessage (errors) {
      // Check if errors is an array and contains at least one item
      if (Array.isArray(errors) && errors.length > 0) {
        return errors[0].message || this.$t('Error')  // Return the message of the first error in the array
      }

      // Check if it's an object with a message property
      if (errors && typeof errors === 'object' && errors.message) {
        return errors.message || this.$t('Error')  // Return the message from the error object
      }

      // Default fallback message
      return this.$t('Error')
    },
    // Method to dynamically generate the title for each tab
    getTabTitle (group) {
      const priceRange = group.minPrice && group.maxPrice
          ? `${group.minPrice} - ${group.maxPrice} ${group.items[0].currency?.code || 'N/A'}`
          : this.$t('No Price')
      const earliestDelivery = group.earliestDeliveryTime
          ? this.$t('Earliest') + ': ' + new Date(group.earliestDeliveryTime).toLocaleDateString()
          : this.$t('No delivery time')
      return `${priceRange} | ${earliestDelivery}`
    },
    // Get result for current data (sender, locality, price, sizes)
    loadItems () {
      if (!this.deliveryRequest) {
        this.addErrorNotification('No sender or locality!')
        return
      }
      this.isWaitOn = true
      let query = {
        debug: !!this.appOptions.debug,
        clearCache: !!this.appOptions.debug,
        weight: this.userWeight || this.weight || 1,
        width: this.userWidth || this.width || 1,
        height: this.userHeight || this.height || 1,
        length: this.userLength || this.length || 1,
        sendDate: this.userSendDate || this.sendDate,
        divisor: this.divisor
      }

      return HALApiService.get(`/delivery-services/calculator/${this.deliveryRequest.id}`, query)
          .then(data => {
            this.calculation = {
              response: data
            }

            this.deliveryServices = data.deliveryServices.reduce((acc, val) => {
              return {
                ...acc,
                [val.id]: val
              }
            }, {})

            this.deliveryServicesValues = data.deliveryServices

            this.servicePoints = data.servicePoints || []

            const titles = {
              simple: {
                title: this.$t('Standard Delivery'),
                icon: 'mdi-truck'
              },
              service_point: {
                title: this.$t('Service Point Collection'),
                icon: 'mdi-package-variant-closed'
              },
              self_service_point: {
                title: this.$t('Pick-Up Point Delivery'),
                icon: 'mdi-store'
              }
            }

            this.result = Object.values(data.rates).reduce((acc, value) => {
              let normalizedType = value.type

              if (!['simple', 'service_point', 'self_service_point'].includes(normalizedType)) {
                normalizedType = 'simple'  // All other types go to 'simple'
              }

              // Initialize the group if it doesn't exist yet
              if (!acc[normalizedType]) {
                acc[normalizedType] = {
                  name: titles[normalizedType] || this.$t('Unknown Type'),
                  type: normalizedType,
                  icon: titles[normalizedType].icon,
                  minPrice: null,
                  maxPrice: null,
                  earliestDeliveryTime: null,
                  latestDeliveryTime: null,
                  items: []
                }
              }

              // Find the corresponding delivery service for this rate
              const deliveryService = this.deliveryServices[value.deliveryService.id]

              // Add errors from the delivery service, if present
              if (!value.price && deliveryService && deliveryService.errors) {
                if (!value.errors) {
                  value.errors = []
                }

                value.errors.push({
                  message: deliveryService.errors.exception || this.$t('Error with the delivery service'),
                  id: deliveryService.id
                })
              }

              if (!value.price && !value.errors) {
                value.errors = [
                  {
                    message: this.$t('This delivery service API is currently experiencing delays in responding.'),
                    id: deliveryService.id
                  }
                ]
              }

              // Add the item to the correct group
              acc[normalizedType].items.push(value)

              return acc
            }, {})

            // Ensure selectedTab is initialized to the first tab type (like 'simple' or 'service_point')
            if (!this.selectedTab && Object.keys(this.result).length > 0) {
              this.selectedTab = Object.keys(this.result)[0] // Set it to the first available tab
            }

            // Sort items by price, placing those without price at the end
            Object.values(this.result).forEach(group => {
              group.items.sort((a, b) => {
                // Handle the case where price is undefined or null
                if (a.price == null) return 1  // `a` goes after `b`
                if (b.price == null) return -1 // `b` goes after `a`
                return a.price - b.price       // Sort by price in ascending order
              })

              // Calculate minimum and maximum price
              group.minPrice = group.items.reduce((min, item) => {
                return item.price != null && (min == null || item.price < min) ? item.price : min
              }, null)

              group.maxPrice = group.items.reduce((max, item) => {
                return item.price != null && (max == null || item.price > max) ? item.price : max
              }, null)

              // Calculate earliest and latest delivery time
              group.earliestDeliveryTime = group.items.reduce((earliest, item) => {
                const deliveryTime = item.deliveryTime?.min || item.deliveryTime?.max?.date
                if (!deliveryTime) return earliest
                return !earliest || new Date(deliveryTime) < new Date(earliest) ? deliveryTime : earliest
              }, null)

              group.latestDeliveryTime = group.items.reduce((latest, item) => {
                const deliveryTime = item.deliveryTime?.max?.date || item.deliveryTime?.min
                if (!deliveryTime) return latest
                return !latest || new Date(deliveryTime) > new Date(latest) ? deliveryTime : latest
              }, null)
            })
          })
          .catch(error => {
            this.addErrorNotification(error)
            console.trace(error)
          })
          .finally(() => {
            this.isWaitOn = false
            // if(this.isExpanded) {
            //     const dimensions =  {
            //         weight: this.userWeight || this.weight ,
            //         dimensions: {
            //             x: `${this.userWidth || this.width}`,
            //             y: `${this.userHeight || this.height}`,
            //             z: `${this.userLength || this.length}`
            //         }
            //     }
            //
            //     this.$emit('update-dimensions', dimensions)
            // }

          })
    },
    enableCatchAll (value) {
      this.$eventBus.update('catchAll', {
        catchAll: value
      })
    },
    handleNumberUpdate (field, value) {
      if (field === 'width') {
        this.userWidth = value
      }
      if (field === 'height') {
        this.userHeight = value
      }
      if (field === 'length') {
        this.userLength = value
      }
      if (field === 'weight') {
        this.userWeight = value
      }
      if (field === 'sendDate') {
        this.userSendDate = value
      }
    }
  }
}
</script>
